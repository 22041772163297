
import { Component, Mixins } from 'vue-property-decorator';
import ResearchPageHeader from '@/components/ResearchPageHeader.vue';
import ResearchsSearchForm, { SearchParamsObject } from '@/components/ResearchsSearchForm.vue';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import { SeriesItem } from '@/data/dto/series-item.dto';
import AudienceComparisonGrid from '@/components/AudienceComparisonGrid.vue';
import {
  MinuteByMinuteSearchParamsDTO,
  MinuteByMinuteProgramDTO,
  MinuteByMinuteSerieDTO,
  MinuteByMinuteExportParamsDTO,
} from '@/data/dto/minute-by-minute.dto';
import { DailyGridDTO } from '@/data/dto/daily-grid.dto';
import { MinuteByMinuteTimeRangesConfigDTO } from '@/data/dto/config.dto';
import Highcharts from 'highcharts';
import HighchartsUtil, { AudienceTooltipDataSeries, IExtendedChartPoint } from '@/utils/highcharts.util';
import DateTimeUtil from '@/utils/datetime.util';
import moment from 'moment';
import HighchartsMixins from '@/mixins/highcharts.mixin';
import ExportButton from '@/components/ExportToExcelButton.vue';
import { ViewersProjectionBaseType, formatNumberToStringWithSymbol } from "@/utils/number.util";
import { TargetBaseType, getTargetBaseTypeByTargetName } from '@/utils/viewers-projection.util';

export type AvailableTimeRanges = 'allDay' | 'morning' | 'afternoon' | 'night' | 'dawn';

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    DataFormatControlsContainer,
    AudienceComparisonGrid,
    ExportButton,
  }
})
export default class MinuteByMinute extends Mixins(HighchartsMixins) {
  selectedTargetBaseType: TargetBaseType = 'residences';
  startZoomItemsCount = 10;
  yKey: ChartDataType = 'audience';
  getGridsTimer: any;
  selectedTimeRange: AvailableTimeRanges | null = 'morning';
  timepickersVisibility = false;
  viewersProjectionBase: ViewersProjectionBaseType = {
    value: 1000000,
    label: 'Milhão',
    baseSymbol: 'M',
    decimalPlaces: 1,
  };

  get exportToExcelSearchParams(): MinuteByMinuteExportParamsDTO | null {
    if(!this.currentSearchParams) return null;

    const params = this.currentSearchParams;

    const headerFields: string[] = [
      params.completeData.market.label,
      params.completeData.target.label,
      params.completeData.mainTvNetwork.label,
      params.completeData.connectedTvsSumType.label,
      params.completeData.startDate,
    ];
    const header = headerFields.join(' - ');
    return {
      minuteByMinute: {
        params: { ...params.valueOnly, startTime: this.selectedStartTime, endTime: this.selectedEndTime },
        header,
      },
    };
  }

  get showViewersProjectionBaseController(): boolean {
    const series = this.chartOptions.series as MinuteByMinuteSerieDTO[];
    const currentSeriesHasViewersProjection = series.some(({ showViewersProjection }) => showViewersProjection);
    return currentSeriesHasViewersProjection && this.yKey === 'audience';
  }

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: MinuteByMinute): Highcharts.Options {
    return {
      chart: {
        zoomType: 'x',
        height: 500,
      },
      legend: {
        enabled: true,
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      navigator: {
        adaptToUpdatedData: false,
      },
      plotOptions: {
        series: {
          turboThreshold:100000,
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%H:%M}',
          rotation: 270,
        },
        tickPositions: [],
        plotBands: [],
        events: {
          afterSetExtremes: function() {
            context.hideOverflowedLabels(this);
            context.scheduleGetTvNetworkGrids(this.min, this.max);
          }
        }
      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        split: false,
        backgroundColor: 'rgba(255,255,255,.9)',
        formatter: function() {
          if (!this.points) return;

          const date = moment(this.x).utc().format('DD/MM/YYYY HH:mm');
          const tooltipSeries: AudienceTooltipDataSeries[] = this.points.map(point => {
            const pointData = point.point as IExtendedChartPoint;
            const seriesData: Highcharts.Series = point.series;

            const tooltipObject: AudienceTooltipDataSeries = {
              color: pointData.color,
              label: `${seriesData.name}: ${pointData.program}`,
              audienceOrSharePercentage: pointData.y,
            };

            const showViewersProjectionBase = !!(typeof pointData.viewersProjection === 'number' && context.yKey === 'audience');
            if (showViewersProjectionBase) {
              tooltipObject.viewersProjection = context.formattedViewersProjection(pointData.viewersProjection, context.viewersProjectionBase);
            }

            return tooltipObject;
          });

          return HighchartsUtil.renderAudienceHTMLTooltip({
            title: date,
            series: tooltipSeries,
          }, context.selectedTargetBaseType);
        },
      },
      series: [],
    };
  }

  defaultTimeRanges: MinuteByMinuteTimeRangesConfigDTO = {
    allDay: { start: '06:00:00', end:'05:59:00'},
    morning: { start: '06:00:00', end:'12:59:00'},
    afternoon: { start: '11:00:00', end:'18:59:00'},
    night: { start: '17:30:00', end:'00:40:00'},
    dawn: { start: '23:59:00', end:'05:59:00'},
  };
  timeRanges = {...this.defaultTimeRanges};
  selectedStartTime = '06:00:00';
  selectedEndTime = '05:59:00';

  timeRangesLabelMap: Record<AvailableTimeRanges, string> = {
    allDay: 'Dia todo',
    morning: 'Manhã',
    afternoon: 'Tarde',
    night: 'Noite',
    dawn: 'Madrugada',
  };

  tvNetworkGrids: DailyGridDTO[] = [];
  currentSearchParams: {
    valueOnly: MinuteByMinuteSearchParamsDTO,
    completeData: Record<string, any>
  } | null = null;

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as MinuteByMinuteSerieDTO[]);
  }

  onViewersProjectionBaseChange(base: ViewersProjectionBaseType): void {
    this.viewersProjectionBase = base;
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  formattedViewersProjection(numberOfViewers = 0, base: ViewersProjectionBaseType = this.viewersProjectionBase ): string {
    return formatNumberToStringWithSymbol(numberOfViewers, base);
  }

  mountChartSeriesYAxis(series: SeriesItem[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  generatePlotBands(programs: MinuteByMinuteProgramDTO[]): any {
    return programs.map((program, i:number) => {
      return {
        color: i % 2 == 0 ? '#ffffff' : '#eeeeee',
        from: program.startTime,
        to: program.endTime,
        label: {
          text: program.name.toUpperCase(),
        },
      }
    });
  }

  generateTickPositions(programs: MinuteByMinuteProgramDTO[]): any {
    return programs.map(program => program.startTime);
  }

  selectCustomTimeRange(range: AvailableTimeRanges): void {
    try {
      this.selectedTimeRange = range;
      this.timepickersVisibility = false;
      const { start, end } = this.timeRanges[range];
      this.setChartZoom(start, end);
    } catch(e) {
      console.log('Período de tempo não disponível')
      this.selectedTimeRange = null;
    }
  }

  setChartZoom(min: string, max: string):void {
    const minUTC = this.generateUTCTime(min);
    const maxUTC = this.generateUTCTime(max);

    const highchart:any = this.$refs.highchart;
    if (!!highchart && !isNaN(minUTC) && !isNaN(maxUTC)) highchart.chart.xAxis[0].setExtremes(minUTC, maxUTC);
  }

  showTimepickers(): void {
    this.selectedTimeRange = null;
    this.timepickersVisibility = true;
  }

  generateUTCTime(time: string): number {
    const date = this.currentSearchParams?.valueOnly.startDate || moment().format('YYYY-MM-DD');
    const gridStart = 6;
    return DateTimeUtil.generateUTCDatetime(date, time, gridStart);
  }

  hideOverflowedLabels(axis: any): void {
    try {
        if (axis?.plotLinesAndBands) {
        axis.plotLinesAndBands.forEach((plotband:any) => {
          const { plotLeft, plotWidth } = axis.chart;
          const from = Math.max(axis.toPixels(plotband.options.from), plotLeft);
          const to = Math.min(axis.toPixels(plotband.options.to), plotLeft + plotWidth);

          const plotBandWidth = to - from;
          const show = plotband.label.getBBox().width < plotBandWidth;

          plotband.label.css({ opacity: Number(show) })
        });
      }
    } catch {
      return;
    }
  }

  utcToStringWithoutSeconds(time: number): string {
    let stringTime = moment(time).utc().format('HH:mm');
    stringTime += ':00';
    return stringTime;
  }

  scheduleGetTvNetworkGrids(start: number | null, end: number | null, timeout = 500): void {
    if (!start || !end) return;
    clearTimeout(this.getGridsTimer);
    const min = this.utcToStringWithoutSeconds(start);
    const max = this.utcToStringWithoutSeconds(end);
    this.selectedStartTime = min;
    this.selectedEndTime = max;
    this.getGridsTimer = setTimeout(() => { this.getTvNetworkGrids(min, max) }, timeout);
  }

  async getMinuteByMinute(params: SearchParamsObject): Promise<void> {
    try {
      const searchParams = params.valueOnly as MinuteByMinuteSearchParamsDTO;
      if (params.completeData.target) {
        this.selectedTargetBaseType = getTargetBaseTypeByTargetName(params.completeData.target.label);
      }

      if ( this.chartOptions.series ) this.chartOptions.series = [];
      this.tvNetworkGrids = [];

      this.chartOptions = this.generateChartOptions(this);

      const getMinuteByMinuteResponse = await this.$store.dispatch('getMinuteByMinute', searchParams);

      if (!getMinuteByMinuteResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        this.resetContent();
        return;
      }

      const { series, programs } = getMinuteByMinuteResponse;

      this.mountChartSeriesYAxis(series);
      const xAxis = this.chartOptions.xAxis as Highcharts.XAxisOptions;
      xAxis.plotBands = this.generatePlotBands(programs)
      xAxis.tickPositions = this.generateTickPositions(programs);
      this.currentSearchParams = {
        valueOnly: searchParams,
        completeData: params.completeData,
      };
      const selectedTimeRange = this.selectedTimeRange || 'morning';
      setTimeout(() => this.setChartZoom(this.timeRanges[selectedTimeRange].start, this.timeRanges[selectedTimeRange].end), 100);
    } catch (e) {
      this.resetContent();
    }
  }

  resetContent(): void {
    this.chartOptions.series = [];
    const xAxis = this.chartOptions.xAxis as Highcharts.XAxisOptions;
    xAxis.plotBands = [];
    xAxis.tickPositions = [];
    this.currentSearchParams = null;
  }

  async getMinuteByMinuteConfigs(): Promise<void> {
    try {
      const { timeRanges } = await this.$store.dispatch('getMinuteByMinuteConfigs');
      this.timeRanges = timeRanges;
    } catch (e) {
      this.timeRanges = {...this.defaultTimeRanges};
     }
  }

  async getTvNetworkGrids(startTime: string, endTime:string): Promise<void> {
    try {
      if (this.currentSearchParams) {
        const params = {...this.currentSearchParams.valueOnly, startTime, endTime };
        const getMinuteByMinuteTvNetworkGridsResponse = await this.$store.dispatch('getMinuteByMinuteTvNetworkGrids', params);

        if (!getMinuteByMinuteTvNetworkGridsResponse) {
          this.$store.commit('showAlert', {
            message: 'Não há dados de grade disponíveis para a consulta realizada.',
            type: 'warning',
          });
          this.tvNetworkGrids = [];
          return;
        }

        const { grids } = getMinuteByMinuteTvNetworkGridsResponse;

        this.tvNetworkGrids = grids;
      }
    } catch (e) {
      this.tvNetworkGrids = [];
    }
  }

  async mounted(): Promise<void> {
    this.getMinuteByMinuteConfigs();
  }
}
